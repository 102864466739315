<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const that = this;
    await that.getFormRule('districtArea-add');
    const regionCode = this.getRule('regionCode');
    const parentCode = that.getRule('parentCode');
    const RegionLevel = that.getRule('regionLevel');
    const latitude = that.getRule('latitude');
    const longitude = that.getRule('longitude');
    latitude.validate = [
      ...latitude.validate,
      {
        trigger: 'blur',
        pattern: /^\d+(\.\d+)?$/,
        message: '仅能输入数字',
      },
    ];
    longitude.validate = [
      ...longitude.validate,
      {
        trigger: 'blur',
        pattern: /^\d+(\.\d+)?$/,
        message: '仅能输入数字',
      },
    ];
    regionCode.validate = [
      ...regionCode.validate,
      {
        trigger: 'blur',
        pattern: '^[a-zA-Z0-9]*$',
        message: '仅能输入英文和数字的组合',
      },
    ];
    parentCode.restful = '/mdm/mdmRegionController/regionSelect';
    parentCode.optionsKey = { label: 'unionName', value: 'regionCode' };
    parentCode.restfulParams = { regionLevel: '0' };
    parentCode.refresh = true;
    parentCode.props.remoteParams = 'unionName';
    parentCode.props.remote = true;
    parentCode.props.filterable = true;
    RegionLevel.on = {
      ...RegionLevel.on,
      change: (val) => {
        console.log(val);
        parentCode.restfulParams = {
          regionLevel: this.getFieldValue('regionLevel') - 1,
        };
        if (that.getFieldValue('parentCode')) {
          that.setValue({ parentCode: '' });
        }
      },
    };
    // console.log(that.formConfig);
    if (that.formConfig.type !== 'add') {
      this.updateRules({
        regionCode: {
          props: {
            disabled: true,
          },
        },
      });
    }
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      request
        .get('/mdm/mdmRegionController/query', { regionCode: this.formConfig.id })
        .then((res) => {
          if (res.success) {
            this.row = res.result;
            this.setValue(res.result);
            parentCode.restfulParams = { regionLevel: res.result.regionLevel - 1 };
            this.reload();
          }
        });
    }
    const email = this.getRule('email');
    email.props = {
      ...email.props,
      maxlength: 6,
    };
    email.validate = [
      {
        trigger: 'blur',
        pattern: /^\d+(\.\d+)?$/,
        message: '请输入6位数字',
      },
      {
        trigger: 'blur',
        len: 6,
        message: '请输入6位数字',
      },
    ];
    this.reload(this.rule);
    console.log(this.formConfig);
  },
  methods: {
    // 赋值
    valueFn() {
      const name = this.formConfig.ratioType;
      const typeList = {
        REGULAR_RATIO: '常规比列',
        HISTORY_ONE_ADDITION: '历史一次性追加',
      };

      return typeList[name];
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        let url = '/mdm/mdmRegionController/save';
        const params = { ...formData };

        if (this.formConfig.type === 'edit') {
          url = '/mdm/mdmRegionController/update';
          // params = Object.assign(this.row, formData);
          params.id = this.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
